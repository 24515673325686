import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
// import { seo, intro, main } from '../content/uslugi'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Intro from '../components/Intro'
import Button from '../components/Button'
import { sLinkTiles } from '../style'
import LinkTile from '../components/LinkTile'
import Main from '../components/Main'

const HcmDeck = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/index.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const seo = {
    url: 'hcm',
    title: {
      pl: 'HCM Deck',
      en: 'HCM',
      ua: 'HCM',
    },
  
    desc: {
      pl: `HCM Deck - odwiedź naszą platformę.`,
      en: `HCM Deck - odwiedź naszą platformę.`,
      ua: `HCM Deck - odwiedź naszą platformę.`,
    },
    keywords: [],
  }

  const button = {
    text: {
      pl: 'Dowiedz się więcej',
      en: 'Learn more',
      ua: 'ДІЗНАТИСЯ БІЛЬШЕ ',
    },
    action: 'SCROLL',
  }

  const body = {
    pl: `<div class=""><p>Przedstawiamy platformę HCM Deck – zobacz najnowsze video od Darii i Mai, które opowiedziały o nowej platformie dla #OmidaTeam.</p><iframe src="https://www.youtube.com/embed/8QLxUfk7InI?si=axsql7jgulGqIN5W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>`
  }

  return (
    <>
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        noIndex
      />
      <Intro
        title={`HCM Deck`}
        desc={``}
        button={button}
        image={image}
        position='center'
      />
      <Main
        h={1}
        title={`HCM Deck`}
        article={body[lang]}
      />
    </Layout>
    </>
  )
}

const sIframeContainer = {
  iframe: {
    width: '100%', 
    height: '100vh',
    border: '0',
    maxWidth: '100%',
  }
}

export default HcmDeck
